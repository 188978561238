import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';

function FirstPage() {
  const [dotCount, setDotCount] = useState(0);
  const csdots = (
    <span style={{fontSize: 50, marginLeft: '2px'}}>
      <span style={{color: dotCount>=1?"#df0024":"transparent"}}>.</span>
      <span style={{color: dotCount>=2?"#00ab9f":"transparent"}}>.</span>
      <span style={{color: dotCount===3?"#f3c300":"transparent"}}>.</span>
    </span>
    );
  
  useEffect(() => {
    const interval = setInterval(() => {
      setDotCount((prevCount)=>(prevCount+1)%4);
    }, 500);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  return (
    <Grid container sx={{maxWidth: 1000, mx: 'auto', fontFamily: 'roboto'}}>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <img src="TUMRT-Colors.png" alt="TUMRT Logo" style={{width: '100%', maxWidth: 500}}/>
        </Box>
      </Grid>
      <Grid item xs={12} textAlign={'center'}>
        <p style={{fontSize: 20, marginTop: 0.5}}>We gamify life!</p>
      </Grid>
      <Grid item xs={12} textAlign={'center'} mt={10} pl={2}>
        <p style={{color: '#333333', fontSize: 30, fontWeight: 'bold'}}>COMING SOON{csdots}</p>
      </Grid>
    </Grid>
  )
}

export default FirstPage;