// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyAaKir2QswrkHLlRxf5DpMgTvVqRARTlCw",
  authDomain: "tumrt-9be37.firebaseapp.com",
  projectId: "tumrt-9be37",
  storageBucket: "tumrt-9be37.appspot.com",
  messagingSenderId: "770464864726",
  appId: "1:770464864726:web:105d129da427eba954ec77",
  measurementId: "G-WR2ZZY8FN1"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

export default db;