import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#2e2e2d',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


export default function BasicTable({ rows }) {
  return (
    <TableContainer sx={{height: '94vh' }} component={Paper}>
      <Table stickyHeader aria-label="simple table">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell sx={{fontFamily: 'Noto Sans Arabic, roboto'}} align='right'>الترتيب</StyledTableCell>
            <StyledTableCell sx={{fontFamily: 'Noto Sans Arabic, roboto'}} align='right'>السمية</StyledTableCell>
            <StyledTableCell sx={{fontFamily: 'Noto Sans Arabic, roboto'}} align='right'>الجملة</StyledTableCell>
            <StyledTableCell sx={{fontFamily: 'Noto Sans Arabic, roboto'}} align='right'>الصكور</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, idx) => (
            <StyledTableRow
              key={idx+1}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <StyledTableCell sx={{fontFamily: 'Noto Sans Arabic, roboto'}} align='right' component="th" scope="row">{idx+1}</StyledTableCell>
              <StyledTableCell sx={{fontFamily: 'Noto Sans Arabic, roboto'}} align="right">{row.name}</StyledTableCell>
              <StyledTableCell sx={{fontFamily: 'Noto Sans Arabic, roboto'}} align="right">{row.sentence}</StyledTableCell>
              <StyledTableCell sx={{fontFamily: 'Noto Sans Arabic, roboto'}} align="right">{row.score}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}