import "noto-sans-arabic/css/300.css";
import "noto-sans-arabic/css/400.css";
import "noto-sans-arabic/css/500.css";
import "noto-sans-arabic/css/600.css";
import "noto-sans-arabic/css/700.css";
import Grid from '@mui/material/Grid';
import { useState, useMemo } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Chip from '@mui/material/Chip';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import db from './firebase'
import { collection, addDoc, serverTimestamp } from "firebase/firestore"; 
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import BestSentenceLeaderBoard from './BestSentenceLeaderBoard';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import { query, orderBy, limit, getDocs } from "firebase/firestore";
import * as React from 'react';

function createData(name, sentence, score, created) {
  return { name, sentence, score, created };
}


async function queryData() {
  const q = query(collection(db, "sentences"), orderBy('score', 'desc'), orderBy('created', 'asc'), limit(10));

  const rows = [];

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    const data=doc.data();
    rows.push(createData(data.name, data.sentence, data.score, data.created));
  });

  return rows;
}

const specialScore = 1000;
const notSpecialScore = 200;

function specialKeys() {
  return darijaLetters.special.map((x)=>(x.value))
}

function notSpecialKeys() {
  return darijaLetters.notSpecial.map((x)=>(x.value))
}

function intesectArrays(ar1, ar2) {
  return ar1.filter((e1) => (
    ar2.includes(e1)
  ))
}

const arabicLatin = [
  {arabic: 'غ', latin: '8'}, 
  {arabic: 'ح', latin: '7'}, 
  {arabic: 'خ', latin: '5'},
  {arabic: 'ع', latin: '3'},
  {arabic: 'ك', latin: 'k'}, 
  {arabic: 'ش', latin: 'x'},
  {arabic: 'ا', latin: 'a'}, 
  {arabic: 'أ', latin: 'A'}, 
  {arabic: 'ؤ', latin: 'o'}, 
  {arabic: 'ئ', latin: 'i'}, 
  {arabic: 'ء', latin: '2'}, 
  {arabic: 'ب', latin: 'b'}, 
  {arabic: 'پ', latin: 'p'},
  {arabic: 'ج', latin: 'j'}, 
  {arabic: 'د', latin: 'd'}, 
  {arabic: 'ه', latin: 'h'},  
  {arabic: 'ة', latin: 'T'}, 
  {arabic: 'و', latin: 'w'}, 
  {arabic: 'ز', latin: 'z'}, 
  {arabic: 'ط', latin: '4'}, 
  {arabic: 'ي', latin: 'y'},
  {arabic: 'ڭ', latin: 'g'}, 
  {arabic: 'ل', latin: 'l'}, 
  {arabic: 'م', latin: 'm'}, 
  {arabic: 'ن', latin: 'n'}, 
  {arabic: 'س', latin: 's'}, 
  {arabic: 'ف', latin: 'f'}, 
  {arabic: 'ڤ', latin: 'v'}, 
  {arabic: 'ص', latin: '6'}, 
  {arabic: 'ق', latin: 'q'}, 
  {arabic: 'ر', latin: 'r'}, 
  {arabic: 'ت', latin: 't'},
  {arabic: 'ض', latin: '9'}
];

function arabize(sentence) {
  let sentence2 = sentence;
  arabicLatin.forEach(m => {
    sentence2 = sentence2.replaceAll(m.latin, m.arabic);
  });
  return sentence2
}

const darijaLetters = {
  special: [
    {value: 'ع', label: 'ع'},
    {value: 'غ', label: 'غ'}, 
    {value: 'ح', label: 'ح'}, 
    {value: 'خ', label: 'خ'},
    {value: 'ك', label: 'ك'}, 
    {value: 'ش', label: 'ش'}
  ],
  notSpecial: [
    {value: 'ا', label: 'ا / أ / ئ / ؤ / ء'}, 
    {value: 'ب', label: 'ب'}, 
    {value: 'پ', label: 'پ'},
    {value: 'ج', label: 'ج'}, 
    {value: 'د', label: 'د/ذ'}, 
    {value: 'ه', label: 'ه'},  
    {value: 'ة', label: 'ة'}, 
    {value: 'و', label: 'و'}, 
    {value: 'ز', label: 'ز'}, 
    {value: 'ط', label: 'ط'}, 
    {value: 'ي', label: 'ي'},
    {value: 'ڭ', label: 'ڭ'}, 
    {value: 'ل', label: 'ل'}, 
    {value: 'م', label: 'م'}, 
    {value: 'ن', label: 'ن'}, 
    {value: 'س', label: 'س'}, 
    {value: 'ف', label: 'ف'}, 
    {value: 'ڤ', label: 'ڤ'}, 
    {value: 'ص', label: 'ص'}, 
    {value: 'ق', label: 'ق'}, 
    {value: 'ر', label: 'ر'}, 
    {value: 'ت', label: 'ت/ث'},
    {value: 'ض', label: 'ض/ظ'}
  ]
};

function countLetters(str) {
  const letterCount = {};
  for (let i = 0; i < str.length; i++) {
    const letter0 = str[i];
    const letter =['أ','ئ','ؤ','ء'].includes(letter0)?'ا':letter0
    if (letterCount[letter]) {
      letterCount[letter]++;
    } else {
      letterCount[letter] = 1;
    }
  }
  return letterCount;
}


function BestSentenceDarija() {
  const [value, setValue] = useState("");

  const lettersCount = useMemo(() => {
    return countLetters(value);
  }, [value]);

  const lettersTotal = useMemo(() => {
    return value.replaceAll(' ', '').length;
  }, [value]);

  const specialCount = useMemo(() => {
    const specials = intesectArrays(specialKeys(), Object.keys(lettersCount));
    return specials.length;
  }, [lettersCount]);

  const specialPoints = useMemo(() => {
    return specialCount*specialScore;
  }, [specialCount]);

  const notSpecialCount = useMemo(() => {
    const notSpecials = intesectArrays(notSpecialKeys(), Object.keys(lettersCount));
    return notSpecials.length;
  }, [lettersCount]);

  const notSpecialPoints = useMemo(() => {
    return notSpecialCount*notSpecialScore;
  }, [notSpecialCount]);

  const spacialBonus = useMemo(() => {
    return (specialCount===specialKeys().length)?2:1; 
  }, [specialCount]);

  const finalPoints = useMemo(() => {
    if(!lettersTotal) {return 0}
    return Math.round((specialPoints * spacialBonus + notSpecialPoints) / (lettersTotal));
  }, [specialPoints, notSpecialPoints, lettersTotal, spacialBonus]);

  
  const handleChange = (event) => {
    const regex = /^[\u0600-\u06FF\u067E\u06AD\u06A4\s]*$/; // Regular expression for Moroccan Darija charset
    console.log(event.target.value);
    const inputValue = arabize(event.target.value.replace('ث', 'ت').replace('ظ', 'ض').replace('ذ', 'د').replace('گ', 'ڭ'));
    console.log(inputValue)
    if (regex.test(inputValue)) {
      setValue(inputValue);
      refreshData();
    }
  };

  function submitSentence(score) {
    if(value && finalPoints){
      const auth = getAuth();
      const userr = auth.currentUser;
      const provider = new GoogleAuthProvider();
  
      if(userr) {
        writeNewSentence(userr.email, userr.displayName, value, finalPoints)
        .then(()=>{
          setValue('');
          refreshData();
        });
      }
      else {
        signInWithPopup(auth, provider)
        .then((result) => {
          // The signed-in user info.
          const user = result.user;
          writeNewSentence(user.email, user.displayName, value, finalPoints)
          .then(()=>{
            setValue('');
            refreshData();
          });
        }).catch((error) => {
          // Handle Errors here.
          const errorMessage = error.message;
          console.log(errorMessage);
          // ...
        });
      }
    }
  
    async function writeNewSentence(email, name, sentence, score) {
      try {
        const docRef = await addDoc(collection(db, "sentences"), {
          email: email,
          name: name,
          sentence: sentence,
          score: score,
          created: serverTimestamp()
        });
        console.log("Document written with ID: ", docRef.id);
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }
  }

  const [rows, setRows] = React.useState([]);

  const refreshData = async () => {
    const newData = await queryData();
    setRows(newData);
  }

  React.useEffect(() => {
    const fetchData = async () => {
      const data = await queryData();
      setRows(data);
    };
    fetchData();
  }, []);

  return (
    <Grid container sx={{fontFamily: 'Noto Sans Arabic, roboto', direction: 'rtl', textAlign: 'right'}}>
      <Grid item xs={12} lg={8}>
        <Grid container sx={{px: {lg: 10}}}>
          <Grid item xs={12} textAlign={'center'}>
            <h1>أحسن جملة بالداريجة</h1>
            <p>حاول تكتب جملة بالداريجة فيها أكبر ڤارييطّي د الحروف بأقل عدد تاع الحروف</p>
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={value}
              onChange={handleChange}
              fullWidth
              multiline
              rows={3}
              InputProps={{
                lang: 'ar', // Specify language as Arabic
                style: { 
                  fontFamily: 'Noto Sans Arabic',
                  fontWeight: 500,
                  fontSize: 20
                }, // Set the font to Scheherazade
              }}
            />
          </Grid>
          <Grid item xs={12} textAlign={'center'} mt={4}>
            <Grid container spacing={2} justifyContent={'center'}>
              {darijaLetters.special.map((lt, idx) => (
                <Grid key={idx} item>
                  <Chip
                    label={lt.label}
                    disabled={!lettersCount[lt.value]}
                    sx={{
                      fontFamily: 'Noto Sans Arabic',
                      fontWeight: 'bold',
                      fontSize: 16
                    }}
                    color={lettersCount[lt.value]?"error":"default"}
                    size="medium"
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} textAlign={'center'} mt={4}>
            <Grid container spacing={2} justifyContent={'center'}>
              {darijaLetters.notSpecial.map((lt, idx) => (
                <Grid key={idx} item>
                  <Chip
                    label={lt.label}
                    disabled={!lettersCount[lt.value]}
                    sx={{
                      fontFamily: 'Noto Sans Arabic',
                      fontWeight: 'bold',
                      fontSize: 16
                    }}
                    color={lettersCount[lt.value]?"primary":"default"}
                    size="medium"
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} textAlign={'center'} mt={6} sx={{fontSize: 20}}>
            <Grid container spacing={2} justifyContent={'center'}>
              <Grid item>
                <Chip
                  label={specialPoints + ' نقطة'}
                  disabled={!specialCount}
                  sx={{
                    fontFamily: 'Noto Sans Arabic',
                    fontWeight: 'bold',
                    fontSize: 16
                  }}
                  color={specialCount? "error":"default"}
                  variant="contained"
                  size="medium"
                />
              </Grid>
              <Grid item>
                <Chip
                  label="2×"
                  disabled={spacialBonus!==2}
                  sx={{
                    fontFamily: 'Noto Sans Arabic',
                    fontWeight: 'bold',
                    fontSize: 16
                  }}
                  color={spacialBonus===2?"warning":"default"}
                  variant="contained"
                  size="medium"
                />
              </Grid>
              <Grid item>
                <IconButton
                  size="small"
                  sx={{
                    p: 0
                  }}
                >
                  <AddCircleRoundedIcon fontSize="large"/>
                </IconButton>
              </Grid>
              <Grid item>
                <Chip
                  label={notSpecialPoints + ' نقطة'}
                  disabled={!notSpecialCount}
                  sx={{
                    fontFamily: 'Noto Sans Arabic',
                    fontWeight: 'bold',
                    fontSize: 16
                  }}
                  color={notSpecialCount? "primary":"default"}
                  variant="contained"
                  size="medium"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} textAlign={'center'} mt={6} sx={{fontSize: 20}}>
            <Grid container spacing={4} justifyContent={'center'} alignContent={'center'}>
              <Grid item>
                <Button
                  disabled={!finalPoints}
                  sx={{
                    fontFamily: 'Noto Sans Arabic',
                    fontWeight: 'bold',
                    fontSize: 16
                  }}
                  variant="contained"
                  color="warning"
                  size="large"
                >
                  {finalPoints + ' نجمة'}
                  <StarRoundedIcon/>
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={!finalPoints || !value}
                  sx={{
                    fontFamily: 'Noto Sans Arabic',
                    fontWeight: 'bold',
                    fontSize: 16
                  }}
                  color="success"
                  variant="contained"
                  size="large"
                  onClick={submitSentence}
                >
                  صيفط
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={4} sx={{p:2, mt: {xs: 5, lg: 0}}}>
        <BestSentenceLeaderBoard rows={rows}/>
      </Grid>
    </Grid>
  )
}

export default BestSentenceDarija;
