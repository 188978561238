import FirstPage from './FirstPage';
import BestSentenceDarija from './BestSentenceDarija';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function MyRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<FirstPage/>} />
        <Route path="/apps/best-sentence-darija" element={<BestSentenceDarija/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default MyRouter;
